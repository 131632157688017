import React from 'react';
import '../styles/Result.css';

function Cancel() {
  return (
    <div className="result-page">
      <div className="result-container cancel">
        <h1>Payment Cancelled</h1>
        <p>Your payment was not completed. If you experienced any issues, our support team is here to help.</p>
        <div className="support-section">
          <p>Contact our support team on Telegram for assistance:</p>
          <a 
            href="https://t.me/patriotcard_support" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="telegram-button"
          >
            <span className="telegram-icon">📱</span>
            Contact Support on Telegram
          </a>
        </div>
        <a href="/" className="home-button">Try Again</a>
      </div>
    </div>
  );
}

export default Cancel; 