import './App.css';
import { useState, useEffect } from 'react';
// Import profile images
import profile1 from './images/profile1.jpg';
import profile2 from './images/profile2.jpg';
import profile3 from './images/profile3.jpg';
import profile4 from './images/profile4.jpg';
import visaLogo from './images/visa_logo.png';
import trumpImage from './images/trump.png';
import applePayLogo from './images/apple-pay.svg';

function App() {
  // Define all state variables at the top
  const [currentReview, setCurrentReview] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  // Define card details
  const cardNumber = "4545 7676 8989 MAGA";
  const expiryDate = "11/27";

  // Update the benefits array to have 6 items
  const benefits = [
    {
      icon: "💳",
      title: "$1,000,000 Weekly Allowance",
      description: "Access at any ATM or Shop worldwide"
    },
    {
      icon: "✓",
      title: "100% Money Back Guarantee",
      description: "Full refund for any issues"
    },
    {
      icon: "👑",
      title: "VIP Access",
      description: "Priority entry to all patriot events"
    },
    {
      icon: "🎧",
      title: "24/7 Premium Support",
      description: "Dedicated concierge service"
    },
    {
      icon: "♾️",
      title: "Lifetime Subscription",
      description: "One-time payment, forever access"
    },
    {
      icon: "🌟",
      title: "Elite Status",
      description: "Exclusive member privileges and recognition"
    }
  ];

  // Keep your reviews array with the imported profile images
  const reviews = [
    {
      name: "John P.",
      location: "Texas",
      text: "This card represents everything I stand for. Proud to be a member! 🇺🇸",
      rating: "★★★★★",
      avatar: profile1,
      date: "2 days ago"
    },
    {
      name: "Sarah M.",
      location: "Florida",
      text: "The most beautiful card design I've ever seen. True American spirit!",
      rating: "★★★★★",
      avatar: profile2,
      date: "1 week ago"
    },
    {
      name: "Mike R.",
      location: "Ohio",
      text: "Finally, a membership that truly represents patriotic values!",
      rating: "★★★★★",
      avatar: profile3,
      date: "3 days ago"
    },
    {
      name: "Emily W.",
      location: "Tennessee",
      text: "Love showing my Patriot Card at rallies. Always gets attention!",
      rating: "★★★★★",
      avatar: profile4,
      date: "5 days ago"
    }
  ];

  // Stats counter effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    const statsSection = document.querySelector('.hero-stats');
    if (statsSection) {
      observer.observe(statsSection);
    }

    return () => observer.disconnect();
  }, []);

  // Counter animation effect
  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        setCount(prev => {
          if (prev < 50) {
            return prev + 1;
          }
          clearInterval(interval);
          return prev;
        });
      }, 60);
    }
    return () => clearInterval(interval);
  }, [isVisible]);

  // Reviews rotation effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [reviews.length]);

  // Keep your existing card flip effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          const card = entry.target;
          const inner = card.querySelector('.benefit-card-inner');
          
          if (entry.isIntersecting) {
            card.style.opacity = '1';
            card.style.transform = 'translateY(0)';
            if (inner) {
              inner.style.transform = 'rotateY(180deg)';
            }
          } else {
            if (inner) {
              inner.style.transform = 'rotateY(0deg)';
            }
            card.style.opacity = '1';
          }
        });
      },
      {
        threshold: 0.3,
        rootMargin: '-50px'
      }
    );

    const cards = document.querySelectorAll('.benefit-card-flip');
    cards.forEach(card => observer.observe(card));

    return () => observer.disconnect();
  }, []);

  // Add this import at the top

  // Add this function inside your App component
  const createPayment = async (referralId) => {
    try {
      const response = await fetch('http://patriotcardusa.com/create-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          referral_id: referralId
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        window.location.href = data.payment_url;
      } else {
        console.error('Payment creation failed');
        alert('Payment service temporarily unavailable. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Payment service temporarily unavailable. Please try again later.');
    }
  };

  // Update the referral ID handling
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralId = urlParams.get('ref') || "REF6588473884"; // Use default if no ref parameter
    
    // Always store either the URL ref or the default ref
    localStorage.setItem('referralId', referralId);
  }, []);

  // Update your handlePayment function
  const handlePayment = async () => {
    setIsLoading(true);
    try {
      const referralId = localStorage.getItem('referralId') || "REF6588473884";
      await createPayment(referralId);
    } catch (error) {
      console.error('Error:', error);
      alert('Payment service temporarily unavailable. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Add this effect to handle navigation highlighting
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id]');
      const scrollPosition = window.scrollY + 100; // Offset for navbar

      sections.forEach(section => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          document.querySelector(`.nav-menu a[href="#${sectionId}"]`)?.classList.add('active');
        } else {
          document.querySelector(`.nav-menu a[href="#${sectionId}"]`)?.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Keep your existing JSX return
  return (
    <div className="App">
      <nav className="navbar">
        <div className="nav-logo">
          <img src={trumpImage} alt="Trump" className="trump-animate" />
          <span className="logo-text">PATRIOT CARD</span>
        </div>
        <div className="nav-menu">
          <a href="#home" onClick={(e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>HOME</a>
          <a href="#solutions" onClick={(e) => {
            e.preventDefault();
            document.querySelector('#solutions').scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}>SOLUTIONS</a>
          <a href="#reviews" onClick={(e) => {
            e.preventDefault();
            document.querySelector('#reviews').scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}>REVIEWS</a>
          <a href="#contact" onClick={(e) => {
            e.preventDefault();
            document.querySelector('#contact').scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}>CONTACT</a>
        </div>
      </nav>

      <section id="home" className="hero">
        <div className="hero-content">
          <div className="hero-text-container">
            <div className="main-heading">
              <h1 className="hero-title">
                <span className="title-highlight">AMERICA FIRST</span>
                <span className="patriot-text">PATRIOT CARD</span>
              </h1>
              
            </div>
          </div>
          
          <div className="card-showcase">
            <div className="card rotating-card">
              <div className="card-front">
                <div className="card-header">
                  <img src={trumpImage} alt="Trump" className="card-trump-icon" />
                  <span>PATRIOT CARD</span>
                  <img src={applePayLogo} alt="Apple Pay" className="apple-pay-logo" />
                </div>
                <div className="card-number">{cardNumber}</div>
                <div className="card-details">
                  <div className="card-holder">
                    <span>PROUD PATRIOT</span>
                    <span className="valid-thru">VALID THRU {expiryDate}</span>
                  </div>
                  <img src={visaLogo} alt="Visa" className="visa-logo" />
                </div>
              </div>
            </div>
          </div>

          <div className="hero-stats">
            <div className="stat-item">
              <span className="stat-number">{isVisible ? `${count}K+` : '0K+'}</span>
              <span className="stat-label">Patriots</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">100%</span>
              <span className="stat-label">American Made</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">24/7</span>
              <span className="stat-label">Support</span>
            </div>
          </div>
        </div>
      </section>

      <section id="solutions" className="solutions">
        <h2 className="section-title">VIRTUAL PATRIOT CARD</h2>
        
        <div className="virtual-card-container">
          {/* Left side - Features list */}
          <div className="features-list">
            <h3>Premium Features:</h3>
            <ul>
              <li>
                <span className="feature-icon">📱</span>
                <div className="feature-text">
                  <strong>Apple Pay Integration</strong>
                  <p>Seamless payments with Apple Pay worldwide</p>
                </div>
              </li>
              <li>
                <span className="feature-icon">🌐</span>
                <div className="feature-text">
                  <strong>Online Shopping</strong>
                  <p>Use for any online purchase globally</p>
                </div>
              </li>
              <li>
                <span className="feature-icon">🏧</span>
                <div className="feature-text">
                  <strong>ATM Access</strong>
                  <p>Withdraw cash at any ATM worldwide</p>
                </div>
              </li>
              <li>
                <span className="feature-icon">🔒</span>
                <div className="feature-text">
                  <strong>Secure Transactions</strong>
                  <p>Advanced encryption for all payments</p>
                </div>
              </li>
              <li>
                <span className="feature-icon">🎧</span>
                <div className="feature-text">
                  <strong>24/7 Support</strong>
                  <p>Lifetime premium support access</p>
                </div>
              </li>
            </ul>
          </div>

          {/* Right side - Card showcase and button */}
          <div className="card-and-button-container">
            <div className="virtual-card-showcase">
              <div className="card-front">
                <div className="card-header">
                  <img src={trumpImage} alt="Trump" className="card-trump-icon" />
                  <span>PATRIOT CARD</span>
                  <img src={applePayLogo} alt="Apple Pay" className="apple-pay-logo" />
                </div>
                <div className="card-number">{cardNumber}</div>
                <div className="card-details">
                  <div className="card-holder">
                    <span>PROUD PATRIOT</span>
                    <span className="valid-thru">VALID THRU {expiryDate}</span>
                  </div>
                  <img src={visaLogo} alt="Visa" className="visa-logo" />
                </div>
              </div>
            </div>
            <button 
              className={`payment-button ${isLoading ? 'loading' : ''}`}
              onClick={handlePayment}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  <span>Processing...</span>
                </div>
              ) : (
                <>
                  <span>BUY NOW</span>
                  <span className="secure-badge">🔒 Secure Payment</span>
                </>
              )}
            </button>
          </div>
        </div>
      </section>

      <section className="benefits">
        <h2 className="section-title">MEMBERSHIP PRIVILEGES</h2>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div className="benefit-card-flip" key={index}>
              <div className="benefit-card-inner">
                <div className="benefit-card-front">
                  {/* Empty front side */}
                </div>
                <div className="benefit-card-back">
                  <div className="benefit-icon">{benefit.icon}</div>
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section id="reviews" className="testimonials">
        <h2 className="section-title">PATRIOT VOICES</h2>
        <div className="reviews-container">
          <div className="reviews-track">
            {/* Double the reviews array to create a seamless loop */}
            {[...reviews, ...reviews].map((review, index) => (
              <div className="review-card" key={index}>
                <div className="review-header">
                  <div className="review-avatar">
                    <img src={review.avatar} alt={review.name} />
                  </div>
                  <div className="review-info">
                    <h4>{review.name}</h4>
                    <p>{review.location} • {review.date}</p>
                  </div>
                  <div className="review-rating">{review.rating}</div>
                </div>
                <p className="review-text">{review.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="cta-section">
        <div className="cta-content">
          <h2>Ready to Join the Movement?</h2>
          <p>Stand with patriots across America. Get your card today!</p>
          <button className="payment-button" onClick={handlePayment}>
            <span>BUY NOW</span>
            <span className="secure-badge">🔒 Secure Payment</span>
          </button>
        </div>
      </section>

      <section id="contact" className="contact">
        <h2 className="section-title">CONTACT US</h2>
        
        <div className="contact-container">
          <div className="contact-card telegram-community">
            <div className="contact-icon">
              <svg className="telegram-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2647 2.42778C21.98 2.19091 21.6364 2.03567 21.2716 1.97858C20.9067 1.92149 20.5341 1.96469 20.1907 2.10357L2.74071 9.33892C2.37011 9.49641 2.05396 9.76073 1.83301 10.1006C1.61207 10.4405 1.49684 10.8402 1.50001 11.2485C1.50319 11.6569 1.62462 12.0547 1.85061 12.3908C2.0766 12.727 2.39683 12.9861 2.77071 13.1374L6.25071 14.4387L8.79071 21.0469C8.82981 21.1501 8.88807 21.2456 8.96238 21.3276C9.03669 21.4096 9.12569 21.4765 9.22471 21.5239C9.32373 21.5713 9.43106 21.5984 9.54025 21.6037C9.64944 21.609 9.75886 21.5924 9.86271 21.5547L13.7587 20.0772L17.0147 22.7902C17.3459 23.0681 17.7565 23.2278 18.1847 23.2467C18.6129 23.2656 19.0362 23.1427 19.3907 22.8934C19.7罗 22.6366 20.0123 22.2728 20.1647 21.8557L23.8907 4.40573C23.9921 4.04954 24.0061 3.67386 23.9315 3.31025C23.8568 2.94664 23.6959 2.60586 23.4627 2.31872C23.2294 2.03158 22.9311 1.80772 22.5947 1.66417C22.2583 1.52062 21.8935 1.46175 21.5307 1.49223L22.2647 2.42778ZM9.37071 14.3402L10.9347 18.4314L11.8387 15.7234L18.1907 9.91245C18.2795 9.83039 18.3394 9.72425 18.3631 9.60878C18.3869 9.4933 18.3734 9.37361 18.3246 9.26661C18.2758 9.15961 18.1939 9.07018 18.0902 9.00936C17.9865 8.94854 17.8659 8.91912 17.7447 8.92495C17.6235 8.93078 17.5064 8.97161 17.4087 9.04245L8.77071 14.8234L9.37071 14.3402Z" fill="currentColor"/>
              </svg>
              <span className="member-count">50K+ Members</span>
            </div>
            <h3>Join Our Community</h3>
            <p>Connect with fellow patriots and stay updated</p>
            <a href="https://t.me/patriotcard" target="_blank" rel="noopener noreferrer" className="telegram-button">
              Join Telegram Channel
              <span className="arrow">→</span>
            </a>
          </div>

          <div className="contact-card telegram-support">
            <div className="contact-icon">
              <svg className="telegram-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2647 2.42778C21.98 2.19091 21.6364 2.03567 21.2716 1.97858C20.9067 1.92149 20.5341 1.96469 20.1907 2.10357L2.74071 9.33892C2.37011 9.49641 2.05396 9.76073 1.83301 10.1006C1.61207 10.4405 1.49684 10.8402 1.50001 11.2485C1.50319 11.6569 1.62462 12.0547 1.85061 12.3908C2.0766 12.727 2.39683 12.9861 2.77071 13.1374L6.25071 14.4387L8.79071 21.0469C8.82981 21.1501 8.88807 21.2456 8.96238 21.3276C9.03669 21.4096 9.12569 21.4765 9.22471 21.5239C9.32373 21.5713 9.43106 21.5984 9.54025 21.6037C9.64944 21.609 9.75886 21.5924 9.86271 21.5547L13.7587 20.0772L17.0147 22.7902C17.3459 23.0681 17.7565 23.2278 18.1847 23.2467C18.6129 23.2656 19.0362 23.1427 19.3907 22.8934C19.7罗 22.6366 20.0123 22.2728 20.1647 21.8557L23.8907 4.40573C23.9921 4.04954 24.0061 3.67386 23.9315 3.31025C23.8568 2.94664 23.6959 2.60586 23.4627 2.31872C23.2294 2.03158 22.9311 1.80772 22.5947 1.66417C22.2583 1.52062 21.8935 1.46175 21.5307 1.49223L22.2647 2.42778ZM9.37071 14.3402L10.9347 18.4314L11.8387 15.7234L18.1907 9.91245C18.2795 9.83039 18.3394 9.72425 18.3631 9.60878C18.3869 9.4933 18.3734 9.37361 18.3246 9.26661C18.2758 9.15961 18.1939 9.07018 18.0902 9.00936C17.9865 8.94854 17.8659 8.91912 17.7447 8.92495C17.6235 8.93078 17.5064 8.97161 17.4087 9.04245L8.77071 14.8234L9.37071 14.3402Z" fill="currentColor"/>
              </svg>
              <span className="support-text">24/7 Support</span>
            </div>
            <h3>Need Help?</h3>
            <p>Our support team is always ready to assist you</p>
            <a href="https://t.me/patriotcard_support" target="_blank" rel="noopener noreferrer" className="telegram-button">
              Contact Support
              <span className="arrow">→</span>
            </a>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-grid">
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#solutions">Solutions</a></li>
              <li><a href="#reviews">Reviews</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </div>
          
          <div className="footer-section">
            <h3>Contact Us</h3>
            <ul className="contact-info">
              <li>📞 1-800-PATRIOT</li>
              <li>📧 info@patriotcard.com</li>
              <li>🏛️ Washington, D.C.</li>
            </ul>
          </div>
          
          <div className="footer-section">
            <h3>Newsletter</h3>
            <div className="newsletter-form">
              <input type="email" placeholder="Enter your email" />
              <button className="subscribe-button">Subscribe</button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
