import React from 'react';
import '../styles/Result.css';

function Success() {
  return (
    <div className="result-page">
      <div className="result-container success">
        <h1>🎉 Thank You for Your Purchase!</h1>
        <p>Your Patriot Card application has been successfully processed.</p>
        <div className="next-steps">
          <h2>Next Steps:</h2>
          <p>Please contact our support team on Telegram for further instructions.</p>
          <a 
            href="https://t.me/patriotcard_support" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="telegram-button"
          >
            <span className="telegram-icon">📱</span>
            Contact Support on Telegram
          </a>
        </div>
        <a href="/" className="home-button">Return to Home</a>
      </div>
    </div>
  );
}

export default Success; 